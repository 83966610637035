
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Loading from './loading';
import NewDoc from './Modals/NewDoc';
import ViewDoc from './Modals/ViewDoc';
import DecisionCheck from './Modals/DecisionCheck';

const Docs = ({currentUser, socket, banner, setBanner}) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            setReload(false);
            window.scrollTo(0, 0);
        }
    }, [setBanner, setReload, banner, reload]);

    const [ loading, setLoading ] = useState(true);

    const [ docList, setDocList ] = useState([]);

    const [ check, setCheck ] = useState({
        show: false,
        proceed: false,
        id: null
    });

    const [ currentDoc, setCurrentDoc ] = useState({
        show: false,
        data: null,
        loading: false,
    });

    if(loading){
        let payload = {
            ip: localStorage.getItem('ip')
        }
        socket.emit('load docs', payload);
        socket.on('docs loaded', data => {
            console.log(data);
            setDocList(data);
            setLoading(false);
        });
    }

    const handleDelete = d => {
        setCheck({proceed: false, id: d._id, show: true});
    }

    useEffect(() => {
        socket.on('doc stored', data => {
            setDocList({...docList, data});
        });
        socket.on('doc removed', data => {
            setDocList(docList.filter(d => d._id !== data._id));
        });
    }, [socket, docList]);
    
    return (
        <>
            {(!loading) ? (
                <>
                    <Row className='cursor-standard'>
                        <Col className='d-flex justify-content-center'>
                            <Card className='pb-3'>
                                <Card.Header className='text-center'>
                                    <h3>Documents</h3>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        {docList.length > 0 ? (
                                            docList.map(d => (
                                                <Col key={d._id}>
                                                    <Card className='text-center' style={{width: '200px', maxWidth: "200px", height: '100%'}} >
                                                        <Card.Body className='cursor-pointer' onClick={() => setCurrentDoc({show: true, loading: true, data: d})}>
                                                            <span className='d-flex justify-content-center'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                                                </svg>
                                                            </span>
                                                            <br/>
                                                            <h6>{d.fileName.split(".")[0]}</h6>
                                                        </Card.Body>
                                                        {(currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                                                            <Card.Footer>
                                                                <Button variant='danger' onClick={() => handleDelete(d)}>Delete</Button>
                                                                <DecisionCheck 
                                                                    check={check}
                                                                    setCheck={setCheck}
                                                                    id={d._id}
                                                                    socketName={'remove document'}
                                                                    title={`Delete Document`}
                                                                    body={`Are you sure you wish to delete this? This action cannot be undone.`}
                                                                />
                                                            </Card.Footer>
                                                        )}
                                                    </Card>
                                                </Col>
                                            ))
                                        ) : (
                                            <p className='text-center mt-5'>No documents currently exist.</p>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <NewDoc 
                        currentUser={currentUser}
                        socket={socket}
                    />
                </>
            ) : (
                <Loading/>
            )}
            <ViewDoc 
                currentDoc={currentDoc}
                setCurrentDoc={setCurrentDoc}
                socket={socket}
            />
        </>
    );
};

export default Docs