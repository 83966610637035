import React, {useEffect, useState} from 'react';
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Buffer } from 'buffer';
import Loading from './loading';
import ImageViewer from './Modals/ImageViewer';

const NewGallery = ({currentUser, socket, setBanner, banner}) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            setReload(false);
            window.scrollTo(0, 0);
        }
    }, [setBanner, setReload, banner, reload]);

    const [ loading, setLoading ] = useState(true);

    const [ images, setImages ] = useState([]);

    const [ imageViewer, setImageViewer ] = useState({
        show: false,
        image: null
    });

    const [ uploadImages, setUploadImages ] = useState({});

    const handleUpload = () => {
        // const input = document.getElementById('fileUpload');
        // const files = input.files;

        socket.emit('store images', uploadImages);
        socket.on('images stored', () => {
            setUploadImages({});
            setImages([]);
            setLoading(true);
        });
    }

    const removeImage = e => {
        const payload = {
            image: e,
            userId: currentUser.user._id
        };
        socket.emit('remove image', payload);
        socket.on('image removed', () => {
            setImages([]);
            setLoading(true);
        });
    }

    const setFiles = e => {
        setUploadImages(e);
    };

    useEffect(() => {
        if(loading && !images.length){
            socket.emit('load images');
            socket.on('images loaded', data => {
                setImages(data);
                setLoading(false);
            });
        }
    });

    return (
        <Container className=''>
            {!loading ? (
                <>
                    {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                        <Row className='mt-1 mb-1'>
                            <Col className='d-flex justify-content-center' style={{maxWidth: '100%'}}>
                                <Card>
                                    <Card.Body>
                                        <Form onSubmit={handleUpload}>
                                            <Col>
                                                <input 
                                                    onChange={e => {setFiles(e.target.files)}}
                                                    id='fileUpload' 
                                                    type='file' 
                                                    multiple
                                                    accept='image/png,image/jpeg,image/webp,image/bmp'
                                                />
                                            </Col>
                                            <Col className='d-flex justify-content-end'>
                                                <Button type='submit' className='btn-block mt-3'>Upload</Button>
                                            </Col>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        {images.map((data, index) => (
                            (data.image && data.type === "gallery") && (
                                <Col md={4} key={index} className='p-3'>
                                    <div className="img-card">
                                        <Image 
                                            onClick={() => setImageViewer({show: true, image: data.image})}
                                            style={{ width: '100%', height: "auto" }}
                                            thumbnail
                                            fluid={true}
                                            src={`data:image/png;base64,${Buffer.from(data.image).toString('base64')}`}
                                        />
                                        {(imageViewer.show && data.image === imageViewer.image) && (
                                            <ImageViewer 
                                                image={images[index].image}
                                                imageViewer={imageViewer}
                                                setImageViewer={setImageViewer}
                                            />
                                        )}
                                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                                            <Button 
                                                className='mt-1' 
                                                type='danger'
                                                onClick={() => removeImage(data._id)}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            )
                        ))}
                    </Row>
                </>
            ) : (
                <Loading />
            )}
        </Container>
    );
}


export default NewGallery;
