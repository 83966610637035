import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Modal, Row, Form } from 'react-bootstrap';
import NewImage from './NewImage';
import Select from 'react-select';

const DistrictSettings = ({district, showSettings, setShowSettings, currentUser, socket}) => {

    const handleClose = () => {
        setShowSettings(false);
    }

    const [distClone, setDistClone] = useState(district);

    const handleSubmit = () => {
        let authToken = localStorage.getItem('auth_token');
        let userId = localStorage.getItem('_id') ? localStorage.getItem('_id') : "";
        socket.emit('district settings', {_id: userId, jwt: authToken, data: {...distClone, status: distClone.status.value}});
        socket.on('district update', data => {
            window.location.reload(false);
        });
        handleClose();
    }

    const modeOptions = [
        { value: 'Allstar' , label: 'Allstar' },
        { value: 'APRS' , label: 'APRS' },
        { value: 'DMR' , label: 'DMR' },
        { value: 'D-Star', label: 'D-Star' },
        { value: 'Echolink' , label: 'Echolink' },
        { value: 'FM' , label: 'FM' },
        { value: 'Fusion' , label: 'Fusion' },
        { value: 'IRLP' , label: 'IRLP' },
        { value: 'NXDN' , label: 'NXDN' },
        { value: 'P25' , label: 'P25' },
        { value: 'Winlink' , label: 'Winlink' },
    ]
    
    return (
        <Modal className='text-black' show={showSettings} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{district.district} Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Select
                            isClearable
                            className='text-black'
                            placeholder="Status"
                            defaultValue={{value: district.status, label: district.status}}
                            onChange={e => distClone.status = e}
                            options={[
                                {value: "On Air", label: "On Air"},
                                {value: "Off Air", label: "Off Air"}
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Select 
                        className='text-black mt-3'
                        isMulti
                        defaultValue={distClone.modes}
                        closeMenuOnSelect={false}
                        onChange={e => setDistClone({...distClone, modes: e})}
                        options={modeOptions}
                    />
                </Row>
                <Row>
                    <Col>
                        <NewImage 
                            currentUser={currentUser}
                            showButton={true}
                            socket={socket}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button onClick={handleSubmit} variant="primary">
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default DistrictSettings;
