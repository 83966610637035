import './App.css';
import 'react-quill/dist/quill.snow.css';
import "react-quill/dist/quill.core.css";
import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './components/Main';
import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    trackingId: "G-F9XV1QFYL7",
    // gaOptions: {...}, // optional
    // gtagOptions: {...}, // optional
  },
]);

// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/", title: "index" });

// Send a custom event
ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
});

const App = () => {
  return (
    <Fragment>
      <Router>
        <Main />
      </Router>
    </Fragment>
  );
};

export default App;
