import React, { useState } from 'react';
import {Button, Col, Image, Modal, Row} from 'react-bootstrap';
import { Buffer } from 'buffer';

const ImageViewer = ({image, imageViewer, setImageViewer}) => {

    console.log(imageViewer)

    const handleClose = () => {
        setImageViewer({show: false});
    }
    
    return (
        <>
        <Modal fullscreen={true} className='text-black' show={imageViewer.show} onHide={handleClose}>
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='d-flex justify-content-center p-3'>
                        <Image
                            style={{maxHeight: "75vh"}}
                            fluid={true}
                            className='img-fluid'
                            src={`data:image/png;base64,${Buffer.from(imageViewer.image).toString('base64')}`}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: "black", color: "white"}}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default ImageViewer;
