import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Green from '../images/green.png'
import Red from '../images/red.png'

const NavCont = ({showAuth, setShowAuth, setCurrentUser, currentUser, handleAddDist, districts, handleLogout}) => {

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

    const handleNav = e => {
        e.preventDefault();
        setExpanded(false);
        navigate(e.target.name, {replace: true})
    }

    const handleAuth = e => {
        setShowAuth(!showAuth);
    }

    if(window.location.href.includes("#login")){
        setShowAuth(true);
        navigate(window.location.pathname, {replace: true});
    }

    const handleAdmin = e => {
        setCurrentUser({...currentUser, adminSettings: !currentUser.adminSettings});
    }

        return (
            <>
                
                    <Navbar expand="lg" bg="dark" variant="dark" expanded={expanded} className="navbar-dark bg-dark">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" onClick={() => setExpanded(!expanded)} />
                            <Navbar.Collapse id="collapsible-navbar-nav" variant="dark" bg="dark" className="justify-content-end">
                                <Nav className="justify-content-end">
                                    <Nav.Link 
                                        onClick={handleNav}
                                        className={window.location.href.split("/")[3] === ('') && 'current-nav-link'}
                                        name="/" active={
                                            window.location.href.split(':3000')[1] === '/' || 
                                            window.location.href.split('.com')[1] === '/' ||
                                            window.location.href.split('.net')[1] === '/' ||
                                            window.location.href.split('.org')[1] === '/'
                                        } 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill pb-1" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                            <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                                        </svg>
                                        Home
                                    </Nav.Link>
                                    <NavDropdown 
                                        active={window.location.href.includes('/district')} 
                                        className={window.location.href.includes('/district') && 'current-nav-link'}
                                        title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-binoculars-fill pb-1" viewBox="0 0 16 16">
                                                    <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z"/>
                                                </svg>
                                                <span>Repeaters</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                    >
                                        {
                                            districts[0] && (
                                                districts
                                                .sort((a, b) => parseInt(a.district) > parseInt(b.district) ? 1 : -1)
                                                .map(d => (
                                                    <NavDropdown.Item 
                                                        key={d._id}
                                                        name={`/repeater/${d.district}`} 
                                                        onClick={handleNav}
                                                    >
                                                        {d.district}
                                                    </NavDropdown.Item>
                                                ))
                                            )
                                        }
                                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && currentUser.adminSettings) && (
                                            <>
                                                <NavDropdown.Item name="/repeater/add" onClick={handleAddDist} className="text-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                                                    </svg>
                                                </NavDropdown.Item>
                                            </>
                                        )}
                                    </NavDropdown>
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/gallery') && 'current-nav-link'}
                                        name="/gallery" active={window.location.href.includes('/gallery')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-images pb-1" viewBox="0 0 16 16">
                                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                                        </svg>
                                        Gallery
                                    </Nav.Link>
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/documents') && 'current-nav-link'}
                                        name="/documents" active={window.location.href.includes('/documents')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-medical-fill pb-1" viewBox="0 0 16 16">
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-3 2v.634l.549-.317a.5.5 0 1 1 .5.866L7 7l.549.317a.5.5 0 1 1-.5.866L6.5 7.866V8.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L5 7l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V5.5a.5.5 0 1 1 1 0zm-2 4.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
                                        </svg>
                                        Documents
                                    </Nav.Link>
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/about') && 'current-nav-link'}
                                        name="/about" active={window.location.href.includes('/about')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-square pb-1" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"/>
                                        </svg>
                                        About
                                    </Nav.Link>
                                    {currentUser.isAuthenticated && (
                                        <NavDropdown title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill pb-1" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                            <span>{currentUser.user.username}</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                        >
                                            {currentUser.user.isAdmin > 0 && (
                                                <>
                                                    { currentUser.user.isAdmin > 8 && (
                                                        <>
                                                            <NavDropdown.Item name='/users' onClick={handleNav}>User Admin</NavDropdown.Item>
                                                            <NavDropdown.Divider />
                                                        </>
                                                    ) }
                                                    <Form.Check 
                                                        className="ms-2"
                                                        type="switch"
                                                        id="admin-switch"
                                                        label="Admin"
                                                        checked={currentUser.adminSettings}
                                                        onChange={handleAdmin}
                                                    />
                                                    <NavDropdown.Divider />
                                                </>
                                            )}
                                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                        </NavDropdown>
                                    )}
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="https://www.facebook.com/profile.php?id=100071854380014" rel="noreferrer" target="_blank" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://twitter.com/wb4gbi" target="_blank" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.instagram.com/hamradiowb4gbi/" target="_blank" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                
            </>
        );
}

export default NavCont;
                                