
import React, { Fragment, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Editor from './TextEditor/Editor';
import NewEditor from './TextEditor/NewEditor';
import Loading from './loading';

const Articles = ({isLoading, data, currentUser, dispatchPosts, type, socket}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        !isLoading ? (
            <Fragment>
                {data.sort((a, b) => a.order > b.order ? 1 : -1).map(p => (
                    (p.type === type &&
                        ((p.visible || currentUser.user.isAdmin > 8 && currentUser.adminSettings) && 
                        <Row key={p._id}>
                            <Col>
                                <div className="col-md-12 d-flex justify-content-center">
                                    <div className="card shadow-lg">
                                        <div className="card-body justify-content-center">
                                            <h3 className="card-title text-center">
                                                {p.title}
                                            </h3>
                                            <div dangerouslySetInnerHTML={{__html: p.text}} />
                                            {(currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && p.type === type) && (
                                                <Editor
                                                    dispatchPosts={dispatchPosts}
                                                    currentUser={currentUser}
                                                    post={p}
                                                    socket={socket}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        )
                    )
                ))}
                <Row>
                    <Col className='d-flex justify-content-center'>
                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                            <Card className='text-center justify-content-center cursor-pointer shadow-lg'>
                                <NewEditor
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    type={type}
                                    socket={socket}
                                />
                            </Card>
                        )}
                    </Col>
                </Row>
            </Fragment>
        ) : (
            <Loading />
        )
    );
};

export default Articles