import React, { useState } from 'react';
import {Button, Card, Col, Container, Form, Modal, Row} from 'react-bootstrap';

const NewDoc = ({currentUser, socket}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const [ file, setFile ] = useState(null);

    const handleFile = e => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = () => {
            // file type is only image.
            if (file.size < 16000000) {
                let payload = {
                    id: currentUser.user._id, 
                    jwt: localStorage.getItem('auth_token'), 
                    fileName: file.name,
                    file,
                    ip: localStorage.getItem('ip')
                }
                socket.emit('new doc', payload);
                setShow(false);
            } else {
                console.warn("You can only upload images.");
            }
    };
    
    return (
        <>
        {( currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings ) && (
        <Row>
            <Col className='d-flex justify-content-center'>
                <Card className='cursor-pointer text-center'>
                    <Card.Body onClick={e => setShow(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                        </svg>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        )}
        <Modal className='text-black shadow-lg' show={show} onHide={e => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>New Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>New File</Form.Label>
                        <Form.Control onChange={handleFile} type="file" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: 'black'}}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default NewDoc;
