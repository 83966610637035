
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import Active from '../../images/On_air.gif';
import Inactive from '../../images/off_air.gif';
import Loading from '../loading';

const Districts = ({districts}) => {

    const nav = useNavigate();

    const handleNav = d => {
        nav(`/repeater/${d.district}`, {replace: true});
    }

    const [ repeaterCollapse, setRepeaterCollapse ] = useState(false);

    useEffect(() => {
        isMobile && (
            setRepeaterCollapse(true)
        )
    }, [isMobile]);

    return (
        districts[0] ? (
            <Col>
                <div className="col-md-12 col-sm-12 d-flex justify-content-center">
                    <div className="card shadow-lg" style={{minWidth: 'min-content'}}>
                        <div className="card-body justify-content-center">
                            
                                <Row className=''>
                                    <Col className='p-4 d-flex justify-content-center'>
                                        <h3 className="card-title text-center">
                                            Repeaters
                                        </h3>
                                    </Col>
                                        { isMobile && (
                                            <Col className='d-flex justify-content-end'>
                                                <Button 
                                                    onClick={() => setRepeaterCollapse(!repeaterCollapse)} 
                                                    style={{height: '30px', marginTop: '23px'}}
                                                    className='ms-2' 
                                                    size='sm'>
                                                    {repeaterCollapse ? (
                                                        "View"
                                                    ) : (
                                                        "Hide"
                                                    )}
                                                </Button>
                                            </Col>
                                        ) }
                                </Row>
                            {(!repeaterCollapse) && (
                                <Row className='justify-content-center'>
                                    {districts[0] && 
                                    (districts
                                        .sort((a, b) => parseInt(a.district) > parseInt(b.district) ? 1 : -1)
                                        .map(d => (
                                            <Col key={d._id} md={12} sm={12} onClick={e => handleNav(d)}> 
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <div className="card shadow-lg cursor-pointer" style={{minWidth: 'max-content'}}>
                                                        <div className="card-body justify-content-center">
                                                            <h6 
                                                                className="card-title text-center"
                                                            >
                                                                    {d.district} MHz
                                                            </h6>
                                                            {d.modes[0] && (
                                                                <div className='modes-box text-center'>
                                                                    {d.modes.map(m => (
                                                                        <Row className='d-flex justify-content-center'>
                                                                            <Col>
                                                                                <span className='text-black'> {m.value} </span>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            <div className='mt-2 d-flex justify-content-center'>
                                                                {d.status === "Off Air" ? (
                                                                    <div className='status-indicator'>
                                                                        <span style={{color: 'red', padding: '6px'}}>Off Air</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className='status-indicator'>
                                                                        <span style={{color: 'green', padding: '6px'}}>On Air</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    )}
                                </Row>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        ) : (
            <Loading />
        )
    );
};

export default Districts