import React, { Fragment, useEffect, useReducer, useState } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { io } from 'socket.io-client';
import AuthPopup from './Auth/AuthPopup';
import Home from './Home';
import NavCont from './Nav';
import NotFound from './NotFound';
import Error from './Modals/Error';
import DistrictPage from './Districts';
import Footer from './Footer';
import NewDistrict from './Modals/NewDistrict';
import Banner from './Banner';
import About from './About';
import Gallery from './Gallery';
import UserManage from './Auth/UserManage';
import Loading from './loading';
import Docs from './Docs';
import { Col, Container, Row } from 'react-bootstrap';
import Districts from './Home/Districts';
import NewGallery from './NewGallery';

const Main = () => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

    const socket = io(ENDPOINT, { transports: ['websocket'] });

    const navigate = useNavigate();

    const [showAuth, setShowAuth] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const [ loadingPosts, setLoadingPosts ] = useState(true);
    const [ loadingDistricts, setLoadingDistricts ] = useState(true);
    const [ userChecked, setUserChecked ] = useState(false);

    const [ tickerText, setTickerText] = useState(null);

    const [addDist, setAddDist] = useState(false);

    const [ checkedIp, setCheckedIp ] = useState(false);

    const [currentUser, setCurrentUser] = useState({
        isAuthenticated: false,
        adminSettings: false,
        user: {}
    });

    const [error, setError] = useState({
        show: false,
        type: "",
        message: ""
    });

    const [ banner, setBanner ] = useState({
        id: '0',
        loading: true,
        showModal: false
    });

    const [{ posts }, dispatchPosts] = useReducer((state, action) => {
        if (action.type === 'NEW_POST') {
            console.log(action)
            const newArray = [...state.posts, {...action.data}];
            return {
                ...state,
                posts: newArray
            }
        } else if(action.type === 'UPDATE_POST'){
            const index = state.posts.findIndex(post => post._id === action.data._id);
            const newArray = [...state.posts];
            newArray[index] = action.data;
            return {
                ...state,
                posts: newArray
            }
        } else if(action.type === "LOAD_POSTS"){
            state.posts = action.data;
            return state
        } else if(action.type === "REMOVE_POST"){
            let newArray = state.posts.filter(post => post._id !== action.data._id);
            return {
                ...state,
                posts: newArray
            }
    }
        return state
    }, { posts: [] });

    const [{ districts }, dispatchDistricts] = useReducer((state, action) => {
        if (action.type === 'create') {
            console.log(action);
            const newArray = [...state.districts, action.district];
            return {
                ...state,
                districts: newArray
            }
        } else if(action.type === 'update'){
            const index = state.districts.findIndex(district => district._id === action.district._id);
            const newArray = [...state.districts];
            newArray[index] = action.district;
            return {
                ...state,
                districts: newArray
            }
        } else if(action.type === "get"){            
            state.districts = action.districts;
            return state
        }
        return state
    }, { districts: [] });


    useEffect(() => {
        if (loadingPosts || loadingDistricts) {
    
          if (loadingPosts) {
            socket.emit('get posts');
            socket.on('load posts', (data) => {
              dispatchPosts({ data, type: "LOAD_POSTS" });
              setLoadingPosts(false);
            });
          }
    
          if (loadingDistricts) {
            socket.emit('districts', { type: 'get' });
            socket.on('districts', (data) => {
              dispatchDistricts(data);
              setLoadingDistricts(false);
            });
          }
          
        }
    }, [socket, loadingPosts, loadingDistricts, dispatchPosts, dispatchDistricts, setLoadingPosts, setLoadingDistricts]);

    useEffect(() => {
        if (loading) {
          if (!userChecked && localStorage.getItem('auth_token')) {
            console.log('hit')
            const authToken = localStorage.getItem('auth_token');
            const userId = localStorage.getItem('_id') ?? '';
            socket.emit('user check', { _id: userId, jwt: authToken });
            setUserChecked(true);
    
            socket.on('checked user', (data) => {
                localStorage.setItem('auth_token', data.jwt);
                setCurrentUser({ ...currentUser, user: data, isAuthenticated: true, adminSettings: true });
            });
    
            socket.on('null user', () => {
              localStorage.removeItem('auth_token');
              localStorage.removeItem('_id');
              console.log('nullUser');
              setCurrentUser({
                isAuthenticated: false,
                adminSettings: false,
                user: {},
              });
            });
          } else {
            setCurrentUser({...currentUser, isAuthenticated: false});
            localStorage.removeItem('auth_token');
            localStorage.removeItem('_id');
          }
          setLoading(false);
        }
    }, [socket, currentUser, loading, setUserChecked, setCurrentUser, setError, userChecked, checkedIp]);
    
    useEffect(() => {

        socket.on('districts', async (data) => {
          if (data.type !== 'get') {
            dispatchDistricts(data);
          }
        });

        socket.on('district update', async data => {
            dispatchDistricts({type: 'update', district: data});
        });
    
        socket.on('new post', (data) => {
          dispatchDistricts({ data, type: 'NEW_POST' });
        });
    
        socket.on('error', (error) => {
          setError({ message: error.message, type: error.type, show: true });
        });
        
    }, [socket, dispatchDistricts]);

    const handleAddDist = () => {
        setAddDist(true);
    }

    const handleLogout = e => {
        e.preventDefault();
        localStorage.removeItem('auth_token');
        localStorage.removeItem('_id');
        setCurrentUser({
            isAuthenticated: false,
            adminSettings: false,
            user: {}
        });
        socket.emit('user logout', currentUser.user);
        navigate('/', {replace: true});
    }

    return (
        (!loadingPosts && !loadingDistricts) ? (
            <Fragment>
                <Banner 
                    currentUser={currentUser}
                    districts={districts}
                    banner={banner}
                    setBanner={setBanner}
                    loading={loading}
                    socket={socket}
                />
                <div className='sticky-top'>
                    <NavCont
                        showAuth={showAuth}
                        setShowAuth={setShowAuth}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        handleAddDist={handleAddDist}
                        loadingDistricts={loadingDistricts}
                        districts={districts}
                        handleLogout={handleLogout}
                        socket={socket}
                    />
                </div>
                {/*     Routes     */}
                <Container fluid>
                <Row>
                    <Col md={2} sm={12}>
                        <Districts
                            districts={districts}
                            socket={socket}
                        />
                    </Col>
                    <Col md={10} sm={12}>
                        <Routes>
                            <Route exact path='/' element={
                                <Home 
                                    loading={loading}
                                    loadingPosts={loadingPosts}
                                    loadingDistricts={loadingDistricts}
                                    posts={posts}
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    districts={districts}
                                    banner={banner}
                                    setBanner={setBanner}
                                    socket={socket}
                                />
                            } />
                            <Route exact path='/repeater/:id' element={
                                <DistrictPage 
                                    districts={districts}
                                    posts={posts}
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    banner={banner}
                                    setBanner={setBanner}
                                    socket={socket}
                                />
                            } />
                            {currentUser.isAuthenticated && (
                                <>
                                    {/* <Route exact path='/nets' element={
                                        <Nets 
                                            districts={districts}
                                            dispatchDistricts={dispatchDistricts}
                                            banner={banner}
                                            setBanner={setBanner}
                                            socket={socket}
                                        />
                                    } /> */}
                                    {/* <Route exact path='/nets/:id' element={
                                        <DistrictNets
                                            districts={districts}
                                            dispatchDistricts={dispatchDistricts}
                                            currentUser={currentUser}
                                            banner={banner}
                                            setBanner={setBanner}
                                            socket={socket}
                                        />
                                    } /> */}
                                    
                                    {/* <Route exact path='/settings' element={
                                        <Settings 
                                            currentUser={currentUser}
                                            setCurrentUser={setCurrentUser}
                                            socket={socket}
                                        />
                                    } /> */}
                                </>
                            )}
                            {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8) && (
                                <Route exact path='/users' element={
                                    <UserManage 
                                        currentUser={currentUser}
                                        socket={socket}
                                    />
                                } />
                            )}
                            <Route exact path='/documents' element={
                                <Docs 
                                    currentUser={currentUser}
                                    socket={socket}
                                    setBanner={setBanner}
                                    banner={banner}
                                />
                            } />
                            <Route exact path='/loading' element={
                                <Loading />
                            } />
                            <Route exact path='/gallery' element={
                                <NewGallery 
                                    currentUser={currentUser}
                                    setBanner={setBanner}
                                    banner={banner}
                                    socket={socket}
                                />
                            } />
                            <Route exact path='/about' element={
                                <About 
                                    loading={loading}
                                    loadingPosts={loadingPosts}
                                    posts={posts}
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    banner={banner}
                                    setBanner={setBanner}
                                    socket={socket}
                                />
                            } />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Col>
                </Row>
                </Container>
                <Footer 
                    districts={districts}
                />

                {/*     Modals     */}
                <AuthPopup 
                    showAuth={showAuth}
                    setShowAuth={setShowAuth}
                    setCurrentUser={setCurrentUser}
                    setError={setError}
                    socket={socket}
                />
                <Error 
                    setError={setError}
                    error={error}
                    socket={socket}
                />
                {currentUser.isAuthenticated && (
                    <NewDistrict
                        show={addDist}
                        setShow={setAddDist}
                        dispatchDistricts={dispatchDistricts}
                        socket={socket}
                    />
                )}
            </Fragment>
        ) : (
            <Loading />
        )
    );
};

export default Main